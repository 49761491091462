<template>
  <div class="hold-transition">
    <loading v-if="cargando" />
    <div class="wrapper">
      <div class="content-wrapper">
        <section class="content-header">
          <div class="container-fluid">
            <div class="row">
              <div class="col-sm-6">
                <h5 class="mb-0">Usuarios</h5>
              </div>
              <div class="col-sm-6">
                <ol class="breadcrumb float-sm-right">
                  <li class="breadcrumb-item">
                    <router-link to="/">Home</router-link>
                  </li>
                  <li class="breadcrumb-item">
                    <router-link to="/Admin">Administrativo</router-link>
                  </li>
                  <li class="breadcrumb-item active">Seguridad</li>
                  <li class="breadcrumb-item active">Usuarios</li>
                </ol>
              </div>
            </div>
          </div>
        </section>
        <section class="content">
          <div class="container-fluid">
            <div class="card">
              <div class="card-header pt-2 pb-2">
                <div class="row">
                  <div class="col-md-11"></div>
                  <div class="col-md-1">
                    <div class="btn-group float-right">
                      <button
                        type="button"
                        class="btn btn-primary"
                        @click="create()"
                        v-if="
                          $store.getters.can('admin.solicitudUsuarios.create')
                        "
                      >
                        <i class="fas fa-plus"></i>
                      </button>
                    </div>
                  </div>
                </div>
              </div>
              <div class="card-body table-responsive p-0">
                <table
                  class="table table-bordered table-striped table-hover table-sm"
                >
                  <thead>
                    <tr>
                      <th>
                        Numero de Documento<input
                          @input="getIndex"
                          v-model="filtros.n_documento"
                          type="text"
                          class="form-control form-control-sm"
                        />
                      </th>
                      <th>
                        Nombres
                        <input
                          @input="getIndex"
                          v-model="filtros.nombres"
                          type="text"
                          class="form-control form-control-sm"
                        />
                      </th>
                      <th>
                        apellidos
                        <input
                          @input="getIndex"
                          v-model="filtros.apellidos"
                          type="text"
                          placeholder="Apellidos"
                          class="form-control form-control-sm"
                        />
                      </th>
                      <th>
                        Correo electronico
                        <input
                          @input="getIndex"
                          v-model="filtros.email"
                          type="email"
                          class="form-control form-control-sm"
                        />
                      </th>
                      <th>
                        Numero de celular
                        <input
                          @input="getIndex"
                          v-model="filtros.telefono"
                          type="text"
                          class="form-control form-control-sm"
                        />
                      </th>
                      <th>
                        Empresa
                        <input
                          class="form-control form-control-sm"
                          v-model="filtros.empresa"
                          @input="getIndex"
                        />
                      </th>
                      <th>
                        Rol o cargo
                        <input
                          type="text"
                          class="form-control form-control-sm"
                          v-model="filtros.rol_cargo"
                          @input="getIndex"
                        />
                      </th>
                      <th>
                        Motivo Solicitud
                        <input
                          type="text"
                          class="form-control form-control-sm"
                          v-model="filtros.motivo"
                          @input="getIndex"
                        />
                      </th>
                      <!--      <th>
                        Líneas Negocio
                        <select class="form-control form-control-sm p-0" v-model="filtros.lineas" @change="getIndex()">
                          <option value="">Todas</option>
                          <option v-for="linea in listasForms.lineas" :key="linea.id" :value="linea.id">
                            {{ linea.descripcion }}
                          </option>
                        </select>
                      </th> -->

                      <th>
                        No de contrato FEC
                        <input
                          type="number"
                          class="form-control form-control-sm"
                          v-model="filtros.n_contrato"
                          @input="getIndex"
                        />
                      </th>
                      <th>
                        Admin de contrato
                        <select
                          class="form-control form-control-sm"
                          v-model="filtros.email_admin"
                          @change="getIndex()"
                        >
                          <option
                            v-for="funcionario in listasForms.funcionarios"
                            :key="funcionario.id"
                            :value="funcionario.id"
                          >
                            {{ funcionario.nombres }}
                          </option>
                        </select>
                      </th>
                      <th>
                        Estado
                        <select
                          class="form-control form-control-sm"
                          v-model="filtros.estado"
                          @change="getIndex()"
                        >
                          <option value="">Todos...</option>
                          <option
                            v-for="estado in listasForms.estados"
                            :key="estado.numeracion"
                            :value="estado.numeracion"
                          >
                            {{ estado.descripcion }}
                          </option>
                        </select>
                      </th>
                      <th v-if="isAdmin === false" class="p-4">acciones</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr v-for="usuario in usuarios.data" :key="usuario.id">
                      <td>{{ usuario.n_documento }}</td>
                      <td>{{ usuario.nombres }}</td>
                      <td>{{ usuario.apellidos }}</td>
                      <td>{{ usuario.email }}</td>
                      <td>{{ usuario.telefono }}</td>
                      <td>{{ usuario.empresa }}</td>
                      <td>{{ usuario.rol_cargo }}</td>
                      <td>{{ usuario.motivo }}</td>
                      <!--                       <td>
                        {{ nombresLineas(usuario.linea_negocio_id) }}
                      </td> -->

                      <td>{{ usuario.n_contrato }}</td>
                      <td>{{ usuario.email_admin }}</td>
                      <td class="text-center">
                        <span
                          class="badge shadow-sm"
                          :class="
                            usuario.estado == 1
                              ? 'badge-warning'
                              : usuario.estado == 2
                              ? 'bg-navy'
                              : usuario.estado == 3
                              ? 'badge-success'
                              : usuario.estado == 4
                              ? 'badge-danger'
                              : ''
                          "
                        >
                          {{ usuario.nEstado }}
                        </span>
                      </td>
                      <span
                        class="badge"
                        data-toggle="modal"
                        data-target="#modal-form-estado"
                        style="cursor: pointer"
                      >
                      </span>
                      <td style="width: 55px" v-if="isAdmin === false">
                        <div class="btn-group float-right">
                          <button
                            type="button"
                            class="btn btn-sm bg-primary"
                            v-if="
                              isAdmin === false &&
                                $store.getters.can(
                                  'admin.solicitudUsuarios.solicitar'
                                ) &&
                                usuario.estado === 1
                            "
                            title="Solicitar"
                            @click="solicitar(usuario.id)"
                          >
                            <i class="far fa-envelope"></i>
                          </button>
                          <button
                            type="button"
                            class="btn btn-sm bg-navy"
                            v-if="
                              isAdmin === false &&
                                $store.getters.can(
                                  'admin.solicitudUsuarios.edit'
                                ) &&
                                usuario.estado === 1
                            "
                            title="Editar"
                            @click="edit(usuario)"
                          >
                            <i class="fas fa-edit"></i>
                          </button>
                          <button
                            type="button"
                            class="btn btn-sm btn-danger"
                            v-if="
                              isAdmin === false &&
                                $store.getters.can(
                                  'admin.solicitudUsuarios.delete'
                                )
                            "
                            title="Eliminar"
                            @click="destroy(usuario.id)"
                          >
                            <i class="fas fa-trash-alt"></i>
                          </button>
                        </div>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
              <div class="card-footer">
                <div class="float-left" v-if="usuarios.total">
                  Mostrando del <b>{{ usuarios.from }}</b> al
                  <b>{{ usuarios.to }}</b> de un total:
                  <b>{{ usuarios.total }}</b> Registros
                </div>
                <div class="float-left" v-else>
                  <span class="badge badge-danger">
                    No hay registros para mostrar
                  </span>
                </div>
                <pagination
                  :data="usuarios"
                  @pagination-change-page="getIndex"
                  :limit="5"
                  class="float-right"
                >
                </pagination>
              </div>
            </div>
          </div>
        </section>
      </div>
    </div>
  </div>
</template>
<script>
import axios from "axios";
import Loading from "../../../../components/Loading";
import pagination from "laravel-vue-pagination";
import { VBPopover } from "bootstrap-vue";
export default {
  name: "UsuarioFronteraIndex",
  directives: {
    "b-popover": VBPopover,
  },
  components: {
    Loading,
    pagination,
  },
  data() {
    return {
      cargando: false,
      usuarios: {},
      isAdmin: false,
      linea_negocio: [],
      filtros: {
        n_documento: null,
        nombres: null,
        apellidos: null,
        email: null,
        telefono: null,
        empresa: null,
        rol_cargo: null,
        motivo: null,
        lineas: [],
        linea_negocio_id: null,
        n_contrato: null,
        funcionario_id: null,
        email_admin: null,
        nEstado: null,
      },
      listasForms: {
        empresas: [],
        lineas: [],
        estados: [],
        funcionarios: [],
      },
    };
  },

  methods: {
    getIndex(page = 1) {
      this.cargando = true;
      this.page = page;
      axios
        .get("/api/admin/usuariosFrontera?page=" + page, {
          params: this.filtros,
        })
        .then((response) => {
          this.usuarios = response.data.data;
          this.isAdmin =
            response.data.isAdmin !== null ? response.data.isAdmin : false;
          this.cargando = false;
        });
    },
    nombresLineas(lineasId) {
      const numeracionesArray = lineasId
        .split(",")
        .map((num) => parseInt(num.trim()));
      const descripciones = this.listasForms.lineas
        .filter((linea) => numeracionesArray.includes(linea.numeracion))
        .map((linea) => linea.descripcion);
      return descripciones.join(", ");
    },
    getEmpresas() {
      axios.get("/api/admin/empresas/lista").then((response) => {
        this.listasForms.empresas = response.data;
      });
    },
    getLineas() {
      axios.get("/api/lista/209").then((response) => {
        this.listasForms.lineas = response.data;
      });
    },
    getFuncionarios() {
      axios.get("/api/admin/funcionarios/lista").then((response) => {
        this.listasForms.funcionarios = response.data;
      });
    },
    getEstados() {
      axios.get("/api/lista/204").then((response) => {
        this.listasForms.estados = response.data;
      });
    },

    create() {
      return this.$router.push({
        name: "/Admin/UsuarioFronteraForm",
        params: { accion: "Crear", id: 0 },
      });
    },
    edit(user) {
      return this.$router.push({
        name: "/Admin/UsuarioFronteraForm",
        params: { accion: "Editar", data_edit: user, id: user.id },
      });
    },

    solicitar(usuario_id) {
      this.cargando = true;
      let data = {
        id: usuario_id,
      };
      axios
        .post("/api/admin/usuariosFrontera/solicitar", data)
        .then((response) => {
          this.getIndex();
          this.$swal({
            icon: "success",
            title: "Solicitud de usuario enviada exitosamente...",
            toast: true,
            position: "top-end",
            showConfirmButton: false,
            timer: 3000,
            timerProgressBar: true,
          });
          this.cargando = false;
        });
    },

    destroy(user) {
      this.$swal({
        title: "Esta seguro de eliminar este Usuario?",
        text: "Los cambios no se pueden revertir!",
        icon: "warning",
        showCancelButton: true,
        confirmButtonText: "Si, Eliminar!",
      }).then((result) => {
        if (result.value) {
          axios
            .delete("/api/admin/usuariosFrontera/" + user)
            .then((response) => {
              this.getIndex();
              this.$swal({
                icon: "success",
                title: "Se elimino el usuario exitosamente...",
                toast: true,
                position: "top-end",
                showConfirmButton: false,
                timer: 3000,
                timerProgressBar: true,
              });
            });
        }
      });
    },
  },

  mounted() {
    this.getIndex();
    this.getEmpresas();
    this.getLineas();
    this.getEstados();
    this.getFuncionarios();
  },
};
</script>
<style>
.popover-header {
  background-color: #001f3f;
  color: white;
}
</style>
